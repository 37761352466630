export default {
  eth: 0.001,
  bnb: 0.001,
  matic: 0.001,
  arbeth: 0.001,
  aureth: 0.001,
  xdai: 0.001,
  ftm: 0.001,
  avax: 0.001,
  movr: 0.001,
  one: 0.001,
  phi: 0.001,
  phi_v2: 0.001,
  ame: 0.001,
  btc: 0.00015,
  ghost: 0.00015,
  next: 0.00015,
  btc_multisig_2n2: 0.00015,
  btc_multisig_2fa: 0.00015,
  noxon: 1,
  swap: 1,
  jot: 1,
  usdt: 0,
  erc: 1,
}
